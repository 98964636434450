import React from "react";

const Contact = () => {
  return (
    <div className="flex flex-col w-full flex-grow bg-gray-600 justify-center p-4">
      <div className="max-w-screen-md mx-auto my-10">
        <h2 className="text-4xl font-extrabold text-teal-500">
          Website still growing...
        </h2>
        <p className="my-4 text-lg text-white">
          This website was created and is still maintained by a teacher as a
          personal passion project and is still being actively developed in my
          spare time as the sole developer.
        </p>
        <p className="mb-4 text-lg font-normal text-white">
          All feedback and ideas are appreciated, however, please be aware that
          I am still a full time teacher and I am therefore not able to respond
          to all emails.
        </p>
        <a
          href="/About"
          className="inline-flex items-center text-lg text-teal-500 hover:underline"
        >
          Read more
          <svg
            className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>

      <div className="my-5">
        <dl className="max-w-md mx-auto text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
              General Enquiries
            </dt>
            <dd className="text-lg font-semibold">
              <a href="mailto:support@edusheet.org">support@edusheet.org</a>
            </dd>
          </div>
          <div className="flex flex-col py-3">
            <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
              Technical Issues and Feature Requests
            </dt>
            <dd className="text-lg font-semibold">
              <a href="mailto:admin@edusheet.org">admin@edusheet.org</a>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default Contact;
