import React, { useState } from 'react';
import classNames from 'classnames';
import logo from '../assets/logo.png';
import { Page } from '../Router';

type Props = {
  activePage: string;
  pageList: Page[];
};

const NavBar = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hamburgerClass = classNames({
    'items-center justify-between w-full md:flex md:w-auto md:order-1': true,
    hidden: !isExpanded,
  });

  return (
    <nav className="bg-gray-800">
      <div className="flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/Home" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-8" alt="Edusheet Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
            Edusheet
          </span>
        </a>
        <div className="flex md:order-2">
          {/* <button
            type="button"
            data-collapse-toggle="navbar-search"
            aria-controls="navbar-search"
            aria-expanded="false"
            className="md:hidden text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"
            onClick={() => setIsExpanded(isExpanded ? false : true)}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button> */}
          {/* <div className="relative hidden md:block">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search icon</span>
            </div>
            <input
              type="text"
              id="search-navbar"
              className="block w-full p-2 ps-10 text-sm  border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search..."
            />
          </div> */}
          <button
            data-collapse-toggle="navbar-search"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
            aria-controls="navbar-search"
            aria-expanded="false"
            onClick={() => setIsExpanded(isExpanded ? false : true)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div className={hamburgerClass} id="navbar-search">
          {/* <div className="relative mt-3 md:hidden">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="search-navbar"
              className="block w-full p-2 ps-10 text-sm border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search..."
            />
          </div> */}
          {/* Remove md:ml-[150px] when restoring search bar */}
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 md:ml-[-150px] rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-gray-800 dark:border-gray-700">
            {props.pageList.map(function (page: Page) {
              return (
                <li key={page.id}>
                  <a
                    href={page.name}
                    className={
                      props.activePage === page.name
                        ? 'block py-2 px-3 text-white bg-teal-500 rounded md:bg-transparent md:p-0 md:text-teal-200'
                        : 'block py-2 px-3 rounded md:p-0 md:hover:text-teal-200 text-white hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'
                    }
                    aria-current="page"
                  >
                    {page.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
