import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ReactComponent as MinusButtonLogo } from "../assets/icons/minusButton.svg";
import { ReactComponent as PlusButtonLogo } from "../assets/icons/plusButton.svg";
import { ReactComponent as LoadingSpinner } from "../assets/icons/loadingSpinner.svg";

type Props = {
  fileName: string;
};

interface Paper {
  id: number;
  value: string;
  questionCount: number;
  isValid: boolean;
}

const SheetPage = (props: Props) => {
  const [students, setStudents] = useState({
    value: "1",
    count: 1,
    isValid: true,
  });
  const [questionCountsByPaper, setQuestionCountsByPaper] = useState<Paper[]>([
    { id: 1, value: "1", questionCount: 1, isValid: true },
  ]);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [filename, setFilename] = useState("");
  const [filepath, setFilepath] = useState("");

  const MAX_STUDENTS = 500;
  const MAX_QUESTIONS = 100;
  const MAX_PAPERS = 10;

  useEffect(() => {
    if (isLoading && !(filename === "" || filepath === "")) {
      setIsLoading(false);
    }
  });

  function updateIsFormValid(isNewValid: boolean, id: number) {
    if (id === 0) {
      setIsFormValid(true);
    }
    if (isNewValid) {
      if (!students.isValid && id !== -1) {
        setIsFormValid(false);
        return;
      }
      for (let paper of questionCountsByPaper) {
        if (!paper.isValid && paper.id !== id) {
          setIsFormValid(false);
          return;
        }
      }
      setIsFormValid(true);
      return;
    }
    setIsFormValid(false);
  }

  function handleAddPaperClick() {
    setQuestionCountsByPaper((oldArray) => [
      ...oldArray,
      { id: oldArray.length + 1, value: "1", questionCount: 1, isValid: true },
    ]);
    updateIsFormValid(true, 0);
  }

  function handleRemovePaperClick() {
    updateIsFormValid(questionCountsByPaper.length > 1, 0);
    setQuestionCountsByPaper((oldArray) =>
      oldArray.filter((item) => {
        return item.id !== oldArray.length;
      })
    );
  }

  function handleGenerateFileClick() {
    if (!isFormValid) {
      return;
    }
    setIsLoading(true);
    let questionList = Array<number>(questionCountsByPaper.length);
    for (let paper of questionCountsByPaper) {
      questionList[paper.id - 1] = paper.questionCount;
    }
    const dataToPost = {
      students: students.count,
      papers: questionList.length,
      questions: questionList,
    };

    fetch("https://api.edusheet.co.uk/sheetInput", {
      cache: "no-cache",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataToPost),
    })
      .then((res) => res.json())
      .then((data) => {
        setFilepath(data["filepath"]);
        setFilename(data["filename"]);
      });
  }

  return (
    <div className="flex justify-center p-4">
      <div className="flex flex-col p-4 rounded-xl my-auto bg-white">
        <div className="flex p-2 justify-between">
          <p className="pr-4 my-auto text-lg">Number of Students:</p>
          <input
            type="number"
            name="Students"
            className={classNames({
              "appearance-none w-24 rounded text-xl py-3 px-4 font-sans": true,
              "border-red-500 text-red-400 border-2 focus:border-red-500 focus:ring-0 focus:outline-none":
                !students.isValid,
              "border-slate-900 text-placeholder-grey border": students.isValid,
            })}
            placeholder=""
            onChange={(e) => {
              setFilename("");
              setFilepath("");
              updateIsFormValid(
                !(+e.target.value < 1 || +e.target.value > MAX_STUDENTS),
                -1
              );
              return setStudents({
                value: e.target.value,
                count: +e.target.value < 1 ? 1 : +e.target.value,
                isValid: !(
                  +e.target.value < 1 || +e.target.value > MAX_STUDENTS
                ),
              });
            }}
            value={students.value}
          />
        </div>
        {questionCountsByPaper.map((paper) => (
          <div key={paper.id} className="flex p-2 justify-between">
            <p className="pr-4 my-auto text-lg">
              {"Questions on Paper " + paper.id + ":"}
            </p>
            <input
              type="number"
              name={"Questions" + paper.id}
              className={classNames({
                "appearance-none w-24 rounded text-xl py-3 px-4 font-sans":
                  true,
                "border-red-500 text-red-400 border-2 focus:border-red-500 focus:ring-0 focus:outline-none":
                  !paper.isValid,
                "border-slate-900 text-placeholder-grey border": paper.isValid,
              })}
              placeholder=""
              onChange={(e) => {
                setFilename("");
                setFilepath("");
                updateIsFormValid(
                  !(+e.target.value < 1 || +e.target.value > MAX_QUESTIONS),
                  paper.id
                );
                return setQuestionCountsByPaper(
                  questionCountsByPaper.map((item) =>
                    item.id === paper.id
                      ? {
                          ...item,
                          value: e.target.value,
                          questionCount:
                            +e.target.value < 1 ? 1 : +e.target.value,
                          isValid: !(
                            +e.target.value < 1 ||
                            +e.target.value > MAX_QUESTIONS
                          ),
                        }
                      : item
                  )
                );
              }}
              value={paper.value}
            />
          </div>
        ))}
        {/*  */}
        <div className="flex justify-center py-2">
          <button onClick={handleRemovePaperClick}>
            <MinusButtonLogo />
          </button>
          <button className="" onClick={handleAddPaperClick}>
            <PlusButtonLogo />
          </button>
        </div>
        {filename === "" || filepath === "" ? (
          <div className="py-2 mx-auto">
            <div
              role="status"
              className={classNames({
                "flex justify-center": true,
                hidden: !isLoading,
              })}
            >
              <LoadingSpinner />
              <span className="sr-only">Loading...</span>
            </div>
            <a
              href="#"
              onClick={handleGenerateFileClick}
              className={classNames({
                "inline-flex mx-auto items-center px-3 py-2 text-md font-medium text-center text-white rounded-lg hover:bg-teal-700":
                  true,
                "cursor-not-allowed bg-teal-700": !isFormValid,
                "bg-teal-500 focus:ring-4 focus:outline-none focus:ring-blue-300":
                  isFormValid,
                hidden: isLoading,
              })}
            >
              Generate File
            </a>
          </div>
        ) : (
          <div className="py-2 mx-auto">
            <a
              href={filepath + filename}
              download={filename}
              className="inline-flex mx-auto items-center px-3 py-2 text-md font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 bg-teal-500 hover:bg-teal-700"
            >
              Download File
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SheetPage;
