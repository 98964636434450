import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const FooterBar = () => {
  return (
    <footer className="p-4 md:p-8 lg:p-10 bg-gray-800">
      <div className="mx-auto max-w-screen-xl text-center">
        <a
          href="/Home"
          className="flex justify-center items-center text-2xl font-semibold text-white"
        >
          <img src={logo} className="h-8 pr-2" alt="Edusheet logo" />
          Edusheet
        </a>
        <p className="my-6 text-gray-400">
          Free customisable spreadsheets for teachers and school leaders.
        </p>
        <ul className="flex flex-wrap justify-center items-center mb-6 text-white">
          <li>
            <a href="/Home" className="mr-4 hover:underline md:mr-6 ">
              Home
            </a>
          </li>
          <li>
            <a href="/About" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="/Donate" className="mr-4 hover:underline md:mr-6">
              Donate
            </a>
          </li>
          <li>
            <Link to="/About#faqs">
              <p className="mr-4 hover:underline md:mr-6">FAQs</p>
            </Link>
          </li>
          <li>
            <a href="/ContactUs" className="mr-4 hover:underline md:mr-6">
              Contact
            </a>
          </li>
        </ul>
        <span className="text-sm sm:text-center text-gray-400">
          © 2024{" "}
          <a href="/Home" className="hover:underline">
            Edusheet™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default FooterBar;
