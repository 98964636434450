import React from "react";
import { ReactComponent as QuestionMark } from "../assets/questionMark.svg";

type QandA = {
  question: string;
  answer: string;
};

const FAQ = () => {
  const faqs: QandA[] = [];
  faqs.push({
    question: "Who can use these spreadsheets?",
    answer:
      "Anyone! Though they are only offered freely for non-commercial use.",
  });
  faqs.push({
    question: "How can I support the site?",
    answer:
      "We are currently working on setting up a way for users to make a donation towards the running of the site. In the meantime, if you encounter any issues or have suggestions for content you would like please get in touch.",
  });
  faqs.push({
    question: "Who is the website for?",
    answer:
      "The customisable spreadsheets here have predominantly been designed by and for teachers for use in data collection for their own classes. Though we hope to increase our offerings in the future.",
  });
  faqs.push({
    question: "Will the resources here remain free?",
    answer:
      "There are no current plans to intoduce any paid content to the site.",
  });

  return (
    <section id="faqs" className="bg-white dark:bg-gray-600">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Frequently asked questions
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
          <div>
            {faqs
              .filter(function (element: QandA, index: number) {
                return index % 2 === 0;
              })
              .map(function (item: QandA, index: number) {
                return (
                  <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                      <QuestionMark />
                      {item.question}
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                      {item.answer}
                    </p>
                  </div>
                );
              })}
          </div>
          <div>
            {faqs
              .filter(function (element: QandA, index: number) {
                return index % 2 === 1;
              })
              .map(function (item: QandA, index: number) {
                return (
                  <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                      <QuestionMark />
                      {item.question}
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                      {item.answer}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
