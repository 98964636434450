import React from 'react';

type Props = {
  pageText: string;
};

const InDevelopment = (props: Props) => {
  return (
    <div className="flex justify-center p-8">
      <div className="p-4 rounded-xl my-auto justify-center text-center bg-white">
        <h1 className="font-medium text-4xl text-blue-slate">
          Website in Development
        </h1>
        <h2 className="pt-2 font-medium text-xl text-blue-slate">
          Check back later for free customisable spreadsheets
        </h2>
        <p className="pt-2 font-medium text-md text-blue-slate">
          {props.pageText}
        </p>
      </div>
    </div>
  );
};

export default InDevelopment;
