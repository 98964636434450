import React, { useState } from 'react';
import NavBar from './components/NavBar';
import FooterBar from './components/FooterBar';
import { Page } from './Router';
import { useSearchParams } from 'react-router-dom';
import Error404 from './pages/Error404';

type Props = {
  innerPage: JSX.Element;
  activePage: string;
  menuList: Page[];
};

const App = (props: Props) => {
  const [activePage] = useState(props.activePage);

  let [searchParams] = useSearchParams();

  const sheets: string[] = ['Nsj23k', 'Pfjn8a', 'fHa73j'];

  return (
    <div className="flex flex-col h-screen">
      <NavBar activePage={activePage} pageList={props.menuList} />
      {activePage === 'sheet' ? (
        sheets.includes(searchParams.get('id') || '') ? (
          props.innerPage
        ) : (
          <Error404 />
        )
      ) : (
        props.innerPage
      )}
      <FooterBar />
    </div>
  );
};

export default App;
