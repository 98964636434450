import React from "react";
import InDevelopment from "../components/InDevelopment";
import FAQ from "../components/FAQ";

const About = () => {
  return (
    <div className="flex flex-col w-full flex-grow bg-gray-600 justify-center">
      <InDevelopment pageText="About Page" />
      <FAQ />
    </div>
  );
};

export default About;
