import React from 'react'
import ArrowButton from '../components/ArrowButton'

const Error404 = () => {
  return (
    <div className="flex flex-col w-full flex-grow bg-gray-600 justify-center">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-teal-500">404</h1>
            <p className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">Something's missing.</p>
            <p className="mb-4 text-lg font-light text-gray-300">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
            <ArrowButton text="Back to Homepage" href="/Home" />
        </div>   
    </div>
    </div>
  )
}

export default Error404