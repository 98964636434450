import React from "react";
import SpreadsheetCard from "../components/SpreadsheetCard";
// import demoImage from "../assets/byQuestionSheet/ByQuestionOverviewPage.png";
import InDevelopment from "../components/InDevelopment";

import pic1 from "../assets/byQuestionSheet/ByQuestionOverviewPage.png";
import pic2 from "../assets/byQuestionSheet/ByQuestionPaperPage.png";

const Spreadsheets = () => {
  return (
    <div className="flex flex-col w-full flex-grow bg-gray-600">
      <InDevelopment pageText="Spreadsheet Page" />
      <div className="flex justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 p-8">
          <SpreadsheetCard
            id="Nsj23k"
            image={[pic1, pic2]}
            title="Question-Wise Analysis"
            description="This spreadsheet template works for multiple assessments with
            different numbers of questions and produces some feedback data for
            any number of students."
          />
          {/* <SpreadsheetCard
            id="Pfjn8a"
            image={demoImage}
            title="Question-Wise Analysis"
            description="This spreadsheet template works for multiple assessments with
            different numbers of questions and produces some feedback data for
            any number of students."
          />
          <SpreadsheetCard
            id="fHa73j"
            image={demoImage}
            title="Question-Wise Analysis"
            description="This spreadsheet template works for multiple assessments with
            different numbers of questions and produces some feedback data for
            any number of students."
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Spreadsheets;
