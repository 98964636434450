import React, { useState } from "react";
import ArrowButton from "./ArrowButton";
import { ReactComponent as RightCarret } from "../assets/icons/rightCarretArrow.svg";
import { ReactComponent as LeftCarret } from "../assets/icons/leftCarretArrow.svg";

type Props = {
  id: string;
  image: string[];
  title: string;
  description: string;
};

const SpreadsheetCard = (props: Props) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [image, setImage] = useState(props.image[imageIndex]);

  function handleRightControllerClick() {
    if (imageIndex === props.image.length - 1) {
      setImageIndex(0);
    } else {
      setImageIndex(imageIndex + 1);
    }
    setImage(props.image[imageIndex]);
  }

  function handleLeftControllerClick() {
    if (imageIndex === 0) {
      setImageIndex(props.image.length - 1);
    } else {
      setImageIndex(imageIndex - 1);
    }
    setImage(props.image[imageIndex]);
  }

  return (
    <div className="p-2">
      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="relative">
          <img className="rounded-t-lg h-56 mx-auto" src={image} alt="" />
          <button
            type="button"
            className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={handleLeftControllerClick}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <LeftCarret />
              <span className="sr-only">Previous</span>
            </span>
          </button>
          <button
            type="button"
            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={handleRightControllerClick}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <RightCarret />
              <span className="sr-only">Next</span>
            </span>
          </button>
        </div>
        <div className="p-5">
          <a href={"sheet?id=" + props.id}>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {props.title}
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {props.description}
          </p>
          <ArrowButton text="Customise" href={"sheet?id=" + props.id} />
        </div>
      </div>
    </div>
  );
};

export default SpreadsheetCard;
