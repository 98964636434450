import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import FileDownloadParams from "./FileDownloadParams";
import { JsxElement } from "typescript";
import { ReactComponent as CrossIcon } from "../assets/icons/cross.svg";

type Props = {
  children: ReactNode;
};

const PopupModal = (props: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {/* <!-- Modal toggle --> */}
      <div className="flex justify-center">
        <button
          data-modal-target="static-modal"
          data-modal-toggle="static-modal"
          className="block text-white bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-teal-500 dark:hover:bg-teal-700 dark:focus:ring-blue-800"
          type="button"
          onClick={() => (showModal ? setShowModal(false) : setShowModal(true))}
        >
          Click Here to Customise Sheet
        </button>
      </div>

      {/* <!-- Main modal --> */}
      <div
        className={classNames({
          "fixed z-40 inset-0 h-screen w-screen flex bg-opacity-50 bg-slate-500 justify-center items-center":
            true,
          hidden: !showModal,
        })}
      >
        <div
          id="static-modal"
          data-modal-backdrop="static"
          aria-hidden="true"
          className="overflow-y-auto overflow-x-hidden inset-0 m-auto z-50 justify-center items-center max-w-md max-h-full"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* <!-- Modal header --> */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Customise Sheet
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="static-modal"
                  onClick={() => setShowModal(false)}
                >
                  <CrossIcon />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-4 md:p-5 space-y-4">{props.children}</div>
              {/* <!-- Modal footer --> */}
              {/* <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                data-modal-hide="static-modal"
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                I accept
              </button>
              <button
                data-modal-hide="static-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Decline
              </button>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
