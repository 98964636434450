import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import InDevelopment from "../components/InDevelopment";
import FileDownloadParams from "../components/FileDownloadParams";
import pic1 from "../assets/byQuestionSheet/ByQuestionOverviewPage.png";
import pic2 from "../assets/byQuestionSheet/ByQuestionPaperPage.png";
import { ReactComponent as Arrow } from "../assets/Arrow1.svg";
import PopupModal from "../components/PopupModal";

// type Props = {
//   fileName: string;
// };

interface Picture {
  id: number;
  name: string;
}

const SheetPage = () => {
  const [searchParams] = useSearchParams();

  const pictures: Picture[] = [
    { id: 0, name: pic1 },
    { id: 1, name: pic2 },
  ];

  const [activeImage, setActiveImage] = useState(pictures[0]);

  return (
    <div className="flex flex-col w-full flex-grow bg-gray-600 justify-center">
      {/* <InDevelopment pageText={"Sheet Id: " + searchParams.get("id")} /> */}
      <section className="py-8 md:py-16 antialiased">
        <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
          <div className="grid lg:grid-cols-2 gap-8">
            <div className="max-w-md lg:max-w-lg mx-auto">
              <div id="product-1-tab-content">
                <div
                  className="flex flex-col p-4 rounded-lg bg-white h-96 justify-between"
                  id="product-1-image-1"
                  role="tabpanel"
                  aria-labelledby="product-1-image-1-tab"
                >
                  <img
                    className="max-w-full my-auto"
                    src={activeImage.name}
                    alt=""
                  />
                </div>
              </div>

              <ul
                className="grid grid-cols-4 gap-4 mt-8"
                id="product-1-tab"
                data-tabs-toggle="#product-1-tab-content"
                data-tabs-active-classes="border-gray-200 dark:border-gray-700"
                data-tabs-inactive-classes="border-transparent hover:border-gray-200 dark:hover:dark:border-gray-700 dark:border-transparent"
                role="tablist"
              >
                {pictures.map(function (item: Picture) {
                  return (
                    <li key={item.id} className="me-2" role="presentation">
                      <button
                        className="h-20 w-20 overflow-hidden border-2 rounded-lg sm:h-20 sm:w-20 md:h-24 md:w-24 p-2 cursor-pointer mx-auto"
                        id="product-1-image-1-tab"
                        data-tabs-target="#product-1-image-1"
                        type="button"
                        role="tab"
                        aria-controls="product-1-image-1"
                        aria-selected="false"
                        onClick={() => setActiveImage(pictures[item.id])}
                      >
                        <img
                          className="object-contain w-full h-full"
                          src={item.name}
                          alt=""
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="mt-6 md:mt-0">
              <h2 className="mt-4 text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
                Single Class Question-Wise Analysis Spreadsheet
              </h2>
              <p className="text-gray-500 dark:text-gray-300 sm:text-l mb-8 font-light lg:text-xl pt-2">
                This spreadsheet template works for multiple assessments with
                different numbers of questions and produces some feedback data
                for any number of students.
              </p>
              <PopupModal>
                <FileDownloadParams fileName={"" + searchParams.get("id")} />
              </PopupModal>
              <hr className="mt-6 border-gray-200 sm:mt-8 dark:border-gray-700" />
              <h3 className="text-gray-500 dark:text-white sm:text-l font-semibold mb-8 lg:text-xl pt-2">
                Customise
              </h3>
              <p className="text-gray-500 dark:text-gray-300 sm:text-l font-light mb-8 lg:text-xl pt-2">
                This spreadsheet has the following possible customisations:
              </p>
              <ul>
                <li className="flex items-center my-6">
                  <Arrow />
                  <p className="text-gray-500 dark:text-gray-300 sm:text-l font-light lg:text-xl pl-4">
                    Number of students: between 1 and 500
                  </p>
                </li>
                <li className="flex items-center my-6">
                  <Arrow />
                  <p className="text-gray-500 dark:text-gray-300 sm:text-l font-light lg:text-xl pl-4">
                    Number of Papers: between 1 and 10
                  </p>
                </li>
                <li className="flex items-center my-6">
                  <Arrow />
                  <p className="text-gray-500 dark:text-gray-300 sm:text-l font-light lg:text-xl pl-4">
                    Number of students: between 1 and 100
                  </p>
                </li>
              </ul>
              <p className="text-gray-500 dark:text-gray-300 sm:text-l font-light lg:text-xl">
                Customise your spreadsheet with the form below. To add or remove
                papers click the + and - buttons. Once finished press generate
                file, wait until the button changes to a download button then
                click download to get your spreadsheet.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <hr className="my-6 border-gray-200 sm:mt-8 dark:border-gray-700" /> */}
      {/* <FileDownloadParams fileName={"" + searchParams.get("id")} /> */}
    </div>
  );
};

export default SheetPage;
