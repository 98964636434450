import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import About from "./pages/About";
import Spreadsheets from "./pages/Spreadsheets";
import Contact from "./pages/Contact";
import SheetPage from "./pages/SheetPage";
import Error404 from "./pages/Error404";

export interface Page {
  id: number;
  name: string;
  component: JSX.Element;
}

const Router = () => {
  const menuPages: Page[] = [
    { id: 1, name: "Home", component: <Home /> },
    { id: 2, name: "About", component: <About /> },
    { id: 3, name: "Spreadsheets", component: <Spreadsheets /> },
  ];

  const miscPages: Page[] = [
    { id: 1, name: "ContactUs", component: <Contact /> },
  ];

  const sheets: string[] = ["Nsj23k"]; //, 'Pfjn8a', 'fHa73j'

  return (
    <BrowserRouter>
      <Routes>
        <Route
          key={0}
          path="/"
          element={
            <App
              innerPage={menuPages[0].component}
              activePage="Home"
              menuList={menuPages}
            />
          }
        ></Route>
        {menuPages.map(function (page: Page) {
          return (
            <Route
              key={page.id}
              path={"/" + page.name}
              element={
                <App
                  innerPage={page.component}
                  activePage={page.name}
                  menuList={menuPages}
                />
              }
            ></Route>
          );
        })}
        {sheets.map(function (id: string) {
          return (
            <Route
              key={menuPages.length + 1}
              path="/sheet"
              element={
                <App
                  innerPage={<SheetPage />}
                  activePage={"sheet"}
                  menuList={menuPages}
                />
              }
            ></Route>
          );
        })}
        {miscPages.map(function (page: Page) {
          return (
            <Route
              key={menuPages.length + sheets.length + page.id}
              path={"/" + page.name}
              element={
                <App
                  innerPage={page.component}
                  activePage={page.name}
                  menuList={menuPages}
                />
              }
            ></Route>
          );
        })}
        <Route
          key={menuPages.length + sheets.length + miscPages.length + 1}
          path="*"
          element={
            <App innerPage={<Error404 />} activePage="" menuList={menuPages} />
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
